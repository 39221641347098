<script setup>
import { useForm } from '@inertiajs/vue3';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

// Components
import ButtonLink from '@/Components/Button/Link.vue';
import Pagination from '@/Components/Pagination/PaginationFE.vue';
import Filters from '@/Components/Table/Filters.vue';
import Table from '@/Components/Table/Table.vue';

// Layouts
import StaffLayout from '@/Layouts/StaffLayout.vue';

const { t } = useI18n();
const route = inject('route');
const props = defineProps({
    query: Object,
    categories: Object,
});

const form = useForm({
    orderBy: props.query.orderBy ?? '',
    orderDir: props.query.orderDir ?? 'desc',
    page: props.query.page ?? 1,
});

const getData = (resetPage = true) => {
    form.page = resetPage ? 1 : form.page;

    form.get(route('staff.faq-categories.index'), {
        preserveState: true,
        preserveScroll: true,
        only: ['categories', 'query'],
    });
};

const typeFilters = {};

const tableSettings = [
    { label: t('Name'), width: '5%', orderBy: 'name' },
    { label: t('Order'), width: '5%', orderBy: 'order_column' },
    { label: '', cell: 'Arrow', width: '2%' },
];

const tableData = computed(() =>
    props.categories.data.map((category) => {
        return {
            id: category.id,
            rowData: [category.name, category.order_column, route('staff.faq-categories.edit', category.id)],
        };
    })
);

const setPage = (e) => {
    form.page = e;
    getData(false);
};
</script>

<template>
    <StaffLayout :title="$t('FAQ categories')">
        <Filters :form="form" @anyUpdate="getData()" :h1="$t('FAQ categories')" :typeFilters="typeFilters">
            <ButtonLink :href="route('staff.faq-categories.create')">
                {{ $t('Add {model}', { model: $t('Category') }) }}
            </ButtonLink>
        </Filters>

        <template v-if="typeof categories !== 'undefined' && categories.data.length > 0">
            <Table
                class="mb-8"
                :loading="form.processing"
                :tableSettings="tableSettings"
                :data="tableData"
                :footerFaded="query.search != '' && query.search != null"
                v-model:orderBy="form.orderBy"
                @update:orderBy="getData()"
                v-model:orderDir="form.orderDir"
                @update:orderDir="getData()"
            />

            <Pagination
                v-if="typeof categories !== 'undefined'"
                :links="categories.links"
                @setPage="setPage"
                :pageData="categories"
                :hideTotal="query.search != '' && query.search != null"
            />
        </template>
        <template v-else>
            <div class="w-full px-4 py-3 overflow-hidden font-bold leading-tight bg-white rounded">
                {{ $t('No {model} found', { model: $t('categories') }) }}
            </div>
        </template>
    </StaffLayout>
</template>
